import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { SiweMessage } from 'siwe';
import { checksumAddress } from 'viem';

const RemoveEmployee = ({ provider }) => {
  const [userAddress, setUserAddress] = useState('');
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(addresses[0]));  // Checksum the address
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);

  useEffect(() => {
    const fetchEmployees = async () => {
      if (userAddress) {
        try {
          const q = query(collection(db, 'employees'), where('employerAddress', '==', userAddress));
          const querySnapshot = await getDocs(q);
          const fetchedEmployees = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEmployees(fetchedEmployees);
        } catch (error) {
          console.error('Error fetching employees:', error);
          toast.error('Failed to fetch employees.');
        }
      }
    };

    fetchEmployees();
  }, [userAddress]);

  const signInWithEthereum = async () => {
    try {
      const message = new SiweMessage({
        domain: window.location.host,
        address: userAddress,
        statement: 'Sign in with Ethereum to the application.',
        uri: window.location.origin,
        version: '1',
        chainId: 1,
      });

      const messageToSign = message.prepareMessage();
      const signature = await provider.request({
        method: 'personal_sign',
        params: [messageToSign, userAddress],
      });

      const recoveredAddress = await message.recoverAddress(signature);
      if (recoveredAddress.toLowerCase() !== userAddress.toLowerCase()) {
        throw new Error('Address mismatch');
      }

      return true;
    } catch (error) {
      console.error('Error during SIWE sign-in:', error);
      toast.error('Sign-In with Ethereum failed.');
      return false;
    }
  };

  const removeEmployee = async (employeeId) => {
    try {
      const isAuthenticated = await signInWithEthereum(provider);

      if (!isAuthenticated) {
        toast.error('Sign In With Ethereum Failed');
        return;
      }

      await deleteDoc(doc(db, 'employees', employeeId));
      setEmployees(employees.filter(employee => employee.id !== employeeId));
      toast.success('Employee removed successfully!');
    } catch (error) {
      console.error('Error removing employee:', error);
      toast.error('Failed to remove employee.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Remove Employee</h2>
        {employees.length > 0 ? (
          <div className="flex flex-col gap-4">
            {employees.map(employee => (
              <div key={employee.id} className="flex justify-between items-center bg-gray-700 rounded-lg px-4 py-2">
                <div>
                  <p className="text-lg font-semibold">{employee.name}</p>
                  <p>Wallet: {employee.walletAddress}</p>
                  <p>Salary: {employee.salary}</p>
                  <p>Tax Percentage: {employee.taxPercent}</p>
                  <p>Bonus: {employee.bonus}</p>
                  <p>Payment Timeline: {employee.paymentTimeline}</p>
                </div>
                <button
                  className="px-4 py-2 bg-red-600 rounded-lg text-white"
                  onClick={() => removeEmployee(employee.id)}
                >
                  Remove Employee
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p>No employees found for this employer.</p>
        )}
      </div>
    </div>
  );
};

export default RemoveEmployee;
