import React, { useRef, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { SiweMessage } from 'siwe';
import { checksumAddress } from 'viem';

const CreatePayRoll = ({ provider }) => {
  const organizationNameRef = useRef();
  const payrollDescriptionRef = useRef();
  const payrollLocationRef = useRef();
  const [userAddress, setUserAddress] = useState('');

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(addresses[0]));  // Checksum the address
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);

  const generateUniqueId = async () => {
    const querySnapshot = await getDocs(collection(db, 'payrolls'));
    const payrollIds = querySnapshot.docs.map(doc => doc.data().id);
    let newId = 1;
    while (payrollIds.includes(newId)) {
      newId += 1;
    }
    return newId;
  };

  const signInWithEthereum = async (provider) => {
    try {
      const message = new SiweMessage({
        domain: window.location.host,
        address: userAddress,
        statement: 'Sign in with Ethereum to the application.',
        uri: window.location.origin,
        version: '1',
        chainId: 1,
      });

      const messageToSign = message.prepareMessage();
      const signature = await provider.request({
        method: 'personal_sign',
        params: [messageToSign, userAddress],
      });

      const recoveredAddress = await message.recoverAddress(signature);
      if (recoveredAddress.toLowerCase() !== userAddress.toLowerCase()) {
        throw new Error('Address mismatch');
      }

      return true;
    } catch (error) {
      console.error('Error during SIWE sign-in:', error);
      toast.error('Sign-In with Ethereum failed.');
      return false;
    }
  };

  const createPayroll = async (name, description, location, address) => {
    try {
      if (!name || !description || !location || !address) {
        toast.error('Please fill in all fields');
        return;
      }
      toast.info('Saving payroll...');

      const isAuthenticated = await signInWithEthereum(provider);

      if (!isAuthenticated) {
        toast.error('Sign In With Ethereum Failed');
        return;
      }

      const payrollId = await generateUniqueId();

      const payrollData = {
        id: payrollId,
        name,
        description,
        location,
        address,
      };

      await addDoc(collection(db, 'payrolls'), payrollData);

      toast.success('Payroll created successfully!');
      window.location.href = '/dapp/employer/manage';
    } catch (error) {
      console.error('Error creating payroll:', error);
      if (error.message === 'Ethereum provider not available') {
        toast.error('Ethereum provider not available. Please ensure your wallet is connected.');
      } else {
        toast.error('Failed to create payroll.');
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Create Payroll</h2>
        <div className="flex flex-col gap-4">
          <input
            ref={organizationNameRef}
            type="text"
            placeholder="Name of Organization"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <textarea
            ref={payrollDescriptionRef}
            placeholder="Description"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={payrollLocationRef}
            type="text"
            placeholder="Location"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            type="text"
            placeholder="Master Address"
            value={userAddress}
            readOnly
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
        </div>
        <button
          className="px-4 py-2 bg-green-600 rounded-lg text-white mt-4"
          onClick={() =>
            createPayroll(
              organizationNameRef.current.value,
              payrollDescriptionRef.current.value,
              payrollLocationRef.current.value,
              userAddress
            )
          }
        >
          Create Payroll
        </button>
      </div>
    </div>
  );
};

export default CreatePayRoll;
