import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { SiweMessage } from 'siwe';
import { checksumAddress } from 'viem';

const ActiveSubscriptions = ({ provider }) => {
  const [userAddress, setUserAddress] = useState('');
  const [upcomingPayments, setUpcomingPayments] = useState([]);

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(addresses[0])); // Checksum the address
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);

  useEffect(() => {
    const fetchUpcomingPayments = async () => {
      if (userAddress) {
        try {
          const q = query(collection(db, 'payments'), where('employeeAddress', '==', userAddress));
          const querySnapshot = await getDocs(q);
          const fetchedPayments = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setUpcomingPayments(fetchedPayments);
        } catch (error) {
          console.error('Error fetching payments:', error);
          toast.error('Failed to fetch upcoming payments.');
        }
      }
    };

    fetchUpcomingPayments();
  }, [userAddress]);

  const signInWithEthereum = async () => {
    try {
      const message = new SiweMessage({
        domain: window.location.host,
        address: userAddress,
        statement: 'Sign in with Ethereum to the application.',
        uri: window.location.origin,
        version: '1',
        chainId: 1,
      });

      const messageToSign = message.prepareMessage();
      const signature = await provider.request({
        method: 'personal_sign',
        params: [messageToSign, userAddress],
      });

      const recoveredAddress = await message.recoverAddress(signature);
      if (recoveredAddress.toLowerCase() !== userAddress.toLowerCase()) {
        throw new Error('Address mismatch');
      }

      return true;
    } catch (error) {
      console.error('Error during SIWE sign-in:', error);
      toast.error('Sign-In with Ethereum failed.');
      return false;
    }
  };

  const claimPayment = async (paymentId) => {
    try {
      const isAuthenticated = await signInWithEthereum(provider);

      if (!isAuthenticated) {
        toast.error('Sign In With Ethereum Failed');
        return;
      }

      const paymentDoc = doc(db, 'payments', paymentId);
      await updateDoc(paymentDoc, { status: 'claimed' });

      setUpcomingPayments(upcomingPayments.filter(payment => payment.id !== paymentId));
      toast.success('Payment claimed successfully!');
    } catch (error) {
      console.error('Error claiming payment:', error);
      toast.error('Failed to claim payment.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Upcoming Payments</h2>
        {upcomingPayments.length > 0 ? (
          <div className="flex flex-col gap-4">
            {upcomingPayments.map(payment => (
              <div key={payment.id} className="flex justify-between items-center bg-gray-700 rounded-lg px-4 py-2">
                <div>
                  <p className="text-lg font-semibold">Organization: {payment.organizationName}</p>
                  <p>Amount: {payment.amount}</p>
                  <p>Next Pay Date: {new Date(payment.nextPayDate.seconds * 1000).toLocaleDateString()}</p>
                </div>
                <button
                  className="px-4 py-2 bg-green-600 rounded-lg text-white"
                  onClick={() => claimPayment(payment.id)}
                >
                  Claim Payment
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p>No upcoming payments found.</p>
        )}
      </div>
    </div>
  );
};

export default ActiveSubscriptions;
