import React, { useRef, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { SiweMessage } from 'siwe';
import { checksumAddress } from 'viem';

const CreateEmployee = ({ provider }) => {
  const employeeNameRef = useRef();
  const salaryRef = useRef();
  const walletAddressRef = useRef();
  const taxPercentRef = useRef();
  const bonusRef = useRef();
  const paymentTimelineRef = useRef();
  const [userAddress, setUserAddress] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState('');

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(addresses[0]));  // Checksum the address
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      if (userAddress) {
        const q = query(collection(db, 'payrolls'), where('address', '==', userAddress));
        const querySnapshot = await getDocs(q);
        const orgs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setOrganizations(orgs);
      }
    };

    fetchOrganizations();
  }, [userAddress]);

  const generateUniqueId = async () => {
    const querySnapshot = await getDocs(collection(db, 'employees'));
    const employeeIds = querySnapshot.docs.map(doc => doc.data().id);
    let newId = 1;
    while (employeeIds.includes(newId)) {
      newId += 1;
    }
    return newId;
  };

  const signInWithEthereum = async (provider) => {
    try {
      const message = new SiweMessage({
        domain: window.location.host,
        address: userAddress,
        statement: 'Sign in with Ethereum to the application.',
        uri: window.location.origin,
        version: '1',
        chainId: 1,
      });

      const messageToSign = message.prepareMessage();
      const signature = await provider.request({
        method: 'personal_sign',
        params: [messageToSign, userAddress],
      });

      const recoveredAddress = await message.recoverAddress(signature);
      if (recoveredAddress.toLowerCase() !== userAddress.toLowerCase()) {
        throw new Error('Address mismatch');
      }

      return true;
    } catch (error) {
      console.error('Error during SIWE sign-in:', error);
      toast.error('Sign-In with Ethereum failed.');
      return false;
    }
  };

  const createEmployee = async (name, salary, walletAddress, taxPercent, bonus, paymentTimeline, organization) => {
    try {
      if (!name || !salary || !walletAddress || !taxPercent || !bonus || !paymentTimeline || !organization) {
        toast.error('Please fill in all fields');
        return;
      }
      toast.info('Saving employee...');

      const isAuthenticated = await signInWithEthereum(provider);

      if (!isAuthenticated) {
        toast.error('Sign In With Ethereum Failed');
        return;
      }

      const employeeId = await generateUniqueId();

      const employeeData = {
        id: employeeId,
        name,
        salary,
        walletAddress,
        taxPercent,
        bonus,
        paymentTimeline,
        employerAddress: userAddress,  // This will help to identify which employer added the employee
        organizationId: organization, // Link employee to organization
      };

      await addDoc(collection(db, 'employees'), employeeData);

      toast.success('Employee created successfully!');
      window.location.href = '/dapp/employer/manage';
    } catch (error) {
      console.error('Error creating employee:', error);
      if (error.message === 'Ethereum provider not available') {
        toast.error('Ethereum provider not available. Please ensure your wallet is connected.');
      } else {
        toast.error('Failed to create employee.');
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Create Employee</h2>
        <div className="flex flex-col gap-4">
          <input
            ref={employeeNameRef}
            type="text"
            placeholder="Employee Name"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={salaryRef}
            type="number"
            placeholder="Salary"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={walletAddressRef}
            type="text"
            placeholder="Wallet Address"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={taxPercentRef}
            type="number"
            placeholder="Tax Percentage"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={bonusRef}
            type="number"
            placeholder="Bonus"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={paymentTimelineRef}
            type="text"
            placeholder="Payment Interval (In Days)"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <select
            value={selectedOrganization}
            onChange={(e) => setSelectedOrganization(e.target.value)}
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          >
            <option value="">Select Organization</option>
            {organizations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Employer Address"
            value={userAddress}
            readOnly
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
        </div>
        <button
          className="px-4 py-2 bg-green-600 rounded-lg text-white mt-4"
          onClick={() =>
            createEmployee(
              employeeNameRef.current.value,
              salaryRef.current.value,
              walletAddressRef.current.value,
              taxPercentRef.current.value,
              bonusRef.current.value,
              paymentTimelineRef.current.value,
              selectedOrganization
            )
          }
        >
          Create Employee
        </button>
      </div>
    </div>
  );
};

export default CreateEmployee;
