import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { baseSepolia } from 'viem/chains';
import ConnectWalletButton from './components/ConnectWalletButton';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { OnchainKitProvider } from '@coinbase/onchainkit';
import { WagmiProvider } from 'wagmi';
import { config } from './config.ts';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CreateEmployee from './components/CreateEmployee';
import RemoveEmployee from './components/RemoveEmployee';
import ActiveSubscriptions from './components/ActiveSubscriptions';
import VaultManagement from './components/VaultManagement'
import CreatePayRoll from './components/CreatePayRoll';

const queryClient = new QueryClient();

const DApp = ({ provider, setProvider }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [artistsDropdownOpen, setArtistsDropdownOpen] = useState(false);
  const [fansDropdownOpen, setFansDropdownOpen] = useState(false);

  const toggleDropdown = (dropdown, event) => {
    event.preventDefault();
    if (dropdown === 'employer') {
      setArtistsDropdownOpen(!artistsDropdownOpen);
      setFansDropdownOpen(false);
    } else if (dropdown === 'employee') {
      setFansDropdownOpen(!fansDropdownOpen);
      setArtistsDropdownOpen(false);
    }
  };

  return (
    <div>
      <header className="bg-gray-800 py-4">
        <div className="max-w-7xl mx-auto px-4 flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold">BaseRoll</Link>
          <nav className="hidden md:flex items-center">
            <Link to="/" className="ml-4">Home</Link>
            <div className="relative ml-4">
              <button
                className="flex items-center"
                onClick={(event) => toggleDropdown('employer', event)}
              >
                Employer
              </button>
              {artistsDropdownOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-gray-700 rounded-md shadow-lg">
                  <Link to="/employer/create" className="block px-4 py-2">Add Employee</Link>
                  <Link to="/employer/remove" className="block px-4 py-2">Remove Employee</Link>
                  <Link to="/employer/manage" className="block px-4 py-2">Manage Vault</Link>
                </div>
              )}
            </div>
            <div className="relative ml-4">
              <button
                className="flex items-center"
                onClick={(event) => toggleDropdown('employee', event)}
              >
                Employee
              </button>
              {fansDropdownOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-gray-700 rounded-md shadow-lg">
                  <Link to="/employee/manage" className="block px-4 py-2">Payment Management</Link>
                </div>
              )}
            </div>
          </nav>
          <div className="flex items-center">
            <ConnectWalletButton setSigner={setProvider} />
            <button
              className="md:hidden text-white ml-4"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Bars3Icon className="h-6 w-6" />
            </button>
          </div>
        </div>
        {isOpen && (
          <nav className="md:hidden">
            <Link to="/" className="block px-4 py-2">Home</Link>
            <div className="block px-4 py-2" onClick={(event) => toggleDropdown('employer', event)}>Employer</div>
            {artistsDropdownOpen && (
              <div className="pl-4">
                <Link to="/employer/create" className="block px-4 py-2">Add Employee</Link>
                <Link to="/employer/remove" className="block px-4 py-2">Remove Employee</Link>
                <Link to="/employer/manage" className="block px-4 py-2">Manage Vault</Link>
              </div>
            )}
            <div className="block px-4 py-2" onClick={(event) => toggleDropdown('employee', event)}>Employee</div>
            {fansDropdownOpen && (
              <div className="pl-4">
                <Link to="/employee/manage" className="block px-4 py-2">Payment Management</Link>
              </div>
            )}
          </nav>
        )}
      </header>
      <div className="mt-8">
        <Routes>
          <Route path="/employer/create" element={<CreateEmployee provider={provider} />} />
          <Route path="/employer/remove" element={<RemoveEmployee provider={provider} />} />
          <Route path="/employer/manage" element={<VaultManagement provider={provider} />} />
          <Route path="/employee/manage" element={<ActiveSubscriptions provider={provider} />} />
          <Route path="*" element={<CreatePayRoll provider={provider} />} />
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  const [provider, setProvider] = useState(null);
 

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <OnchainKitProvider chain={baseSepolia}>
          <Router>
            <div className="bg-gray-900 text-white min-h-screen">
              <Routes>
                <Route path="*" element={<DApp provider={provider} setProvider={setProvider} />} />
              </Routes>
            </div>
          </Router>
        </OnchainKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
