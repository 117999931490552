import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { checksumAddress, encodeFunctionData, decodeFunctionResult, createPublicClient, http } from 'viem';
import { baseSepolia } from 'viem/chains';

const VaultManagement = ({ provider }) => {
  const [userAddress, setUserAddress] = useState('');
  const [contractBalance, setContractBalance] = useState(0);
  const [upcomingPayments, setUpcomingPayments] = useState([]);

  const contractAddress = '0xYourPayrollEscrowContractAddress'; // Replace with your contract address

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(addresses[0])); // Checksum the address
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);

  useEffect(() => {
    const fetchContractBalance = async () => {
      try {
        const client = createPublicClient({
          chain: baseSepolia,
          transport: http(),
        });

        const balanceData = encodeFunctionData({
          abi: [
            {
              constant: true,
              inputs: [],
              name: 'checkBalance',
              outputs: [{ name: '', type: 'uint256' }],
              type: 'function',
            },
          ],
          functionName: 'checkBalance',
        });

        const result = await client.call({
          to: contractAddress,
          data: balanceData,
        });

        const balance = decodeFunctionResult({
          abi: [
            {
              constant: true,
              inputs: [],
              name: 'checkBalance',
              outputs: [{ name: '', type: 'uint256' }],
              type: 'function',
            },
          ],
          functionName: 'checkBalance',
          data: result,
        });

        setContractBalance(Number(balance) / 1e18); // Convert from Wei to Ether
      } catch (error) {
        console.error('Error fetching contract balance:', error);
        toast.error('Failed to fetch contract balance.');
      }
    };

    const fetchUpcomingPayments = async () => {
      if (userAddress) {
        try {
          const q = query(collection(db, 'payments'), where('employeeAddress', '==', userAddress));
          const querySnapshot = await getDocs(q);
          const fetchedPayments = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setUpcomingPayments(fetchedPayments);
        } catch (error) {
          console.error('Error fetching payments:', error);
          toast.error('Failed to fetch upcoming payments.');
        }
      }
    };

    fetchContractBalance();
    fetchUpcomingPayments();
  }, [userAddress]);

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Vault Management</h2>
        <div className="mb-4">
          <h3 className="text-xl font-semibold">Contract Balance</h3>
          <p>{contractBalance.toFixed(4)} ETH</p>
        </div>
        <div className="mb-8">
          <h3 className="text-xl font-semibold">Upcoming Payments</h3>
          {upcomingPayments.length > 0 ? (
            <div className="flex flex-col gap-4">
              {upcomingPayments.map(payment => (
                <div key={payment.id} className="flex justify-between items-center bg-gray-700 rounded-lg px-4 py-2">
                  <div>
                    <p className="text-lg font-semibold">Organization: {payment.organizationName}</p>
                    <p>Amount: {payment.amount}</p>
                    <p>Next Pay Date: {new Date(payment.nextPayDate.seconds * 1000).toLocaleDateString()}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No upcoming payments found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VaultManagement;
